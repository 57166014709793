import { mapActions, mapState } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import { showSnackBar } from "@/helpers/globalHelpers";
export default {
  name: "UpdatePlanType",
  components: {
  },
  data() {
    return {
      e1: 1,
      isLoadingPlanType:false,
      isLoadingSavePlanType: false,
      isLoadingProfile: false,
      isLoadingService: false,
      profiles: [],
      profilesSelected: [],
      fieldValues: {},
      selectedServices: [],
      headers: [
        { text: "Nombre de perfil", sortable: false, value: "name" },
        { text: "Acciones", sortable: false, value: "action", align: "end" },
      ],
      fieldHeaders: [
        { text: "Nombre del Campo", value: "field_name", sortable: false },
        { text: "Tipo de Dato", value: "data_type", sortable: false },
        { text: "Requerido", value: "is_required", sortable: false },
        { text: "Acciones", value: "action", sortable: false, align: "end" },
      ],
      formPlanType: {
        type_name: null,
        criteria_name: null,
        flag_action_plan_name: true,
        flag_responsibles: true,
        flag_service_selection: true,
        flag_notify_users: true,
        flag_multiple_dates: true,
        fields: [],
        profiles: [],
        field_groups: []
      },
      field: {
        field_name: null,
        data_type: "TEXTO",
        is_required: false,
      },
      requiredRule: (value) => !!value || "Este campo es requerido",
      optionalRule: () => true,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Tipos de plan de acción",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ListPlanType",
          },
        },
        {
          text: "Actualizar tipo de plan de acción",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
    },

    ...mapState('security', ['costCenters']),
    ...mapState("plan_type", ["planType"]),

    filteredProfiles() {
      return this.profiles.filter(
        (profile) =>
          !this.formPlanType.profiles.some(
            (selectedProfile) => selectedProfile.id === profile.id
          )
      );
    },

    isStepTwoNextButtonEnabled() {
      return !!this.formPlanType.type_name && !!this.formPlanType.criteria_name;
    },

    isAddFieldButtonEnabled() {
      return !!this.field.field_name && !!this.field.data_type;
    },

    isStepThreeNextButtonEnabled() {
      return this.formPlanType.fields.length > 0;
    },

    dynamicFieldGroupHeaders() {
      const fieldHeaders = this.formPlanType.fields.map(field => ({
        text: field.field_name,
        value: field.field_name,
        sortable: false
      }));
      return [
        ...fieldHeaders,
        { text: 'Servicios', value: 'servicios', sortable: false },
        { text: 'Acciones', value: 'action', sortable: false, align: 'end' }
      ];
    }
  },
  created() {
    this.getProfilesData();
    this.getCostCenters();
    this.getPlanType();
  },
  methods: {
    ...mapActions('security', ['getProfiles', 'getCostCenter']),
    ...mapActions('plan_type', ['getPlantypeById', 'editPlanType']),

    async getPlanType() {
      this.isLoadingPlanType = true;
      const { planTypeId } = this.$route.params;
      const { error } = await this.getPlantypeById(planTypeId);
      if (error) {
        showError(error);
        this.isLoadingPlanType = false;
        return;
      }
    
      const { type_name, criteria_name, flag_action_plan_name, flag_responsibles, flag_service_selection, flag_notify_users, flag_multiple_dates, custom_fields, profiles, groupings } = this.planType;

      this.formPlanType.type_name = type_name;
      this.formPlanType.criteria_name = criteria_name;
      this.formPlanType.flag_action_plan_name = flag_action_plan_name;
      this.formPlanType.flag_responsibles = flag_responsibles;
      this.formPlanType.flag_service_selection = flag_service_selection;
      this.formPlanType.flag_notify_users = flag_notify_users;
      this.formPlanType.flag_multiple_dates = flag_multiple_dates;

      this.formPlanType.profiles = profiles.map((profile) => ({
        id: profile.profile_id,
        name: profile.profile.name,
      }));

      this.formPlanType.fields = custom_fields.map((field) => ({
        id: field.id,
        field_name: field.field_name,
        is_required: field.is_required,
        data_type: field.data_type,
      }));

      this.formPlanType.field_groups = groupings.map((group) => ({
        id: group.id,
        service: group.services.map((service) => service.service_id),
        grouped_fields: group.field_groups.map((fieldGroup) => ({
          group_field_id: fieldGroup.id,
          field_name: fieldGroup.field.field_name,
          value: fieldGroup.value,
        })),
      }));
    
      this.isLoadingPlanType = false;
    },

    async getProfilesData() {
      this.isLoadingProfile = true;
      const { error, response } = await this.getProfiles({ app_id: process.env.VUE_APP_ID })
      if (error) showError(error);
      this.profiles = response;
      this.isLoadingProfile = false;
    },

    async getCostCenters() {
      this.isLoadingService = true;
      const { error } = await this.getCostCenter();
      if (error) showError(error);
      this.isLoadingService = false;
    },

    addProfilesToTable() {
      const uniqueProfiles = this.profilesSelected
        .filter(
          (selectedId) =>
            !this.formPlanType.profiles.some((profile) => profile.id === selectedId)
        )
        .map((id) => this.profiles.find((profile) => profile.id === id));

      this.formPlanType.profiles.push(...uniqueProfiles);
      this.profilesSelected = [];
    },

    removeProfile(profileId) {
      const existingProfile = this.planType?.profiles.find(
        (profile) => profile.profile_id === profileId
      );
    
      if (existingProfile) {
        this.$swal({
          text: "Este perfil ya está registrado. ¿Estás seguro de eliminarlo?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0039a6",
          cancelButtonColor: "grey",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sí, eliminar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.formPlanType.profiles = this.formPlanType.profiles.filter(
              (profile) => profile.id !== profileId
            );
            showSnackBar("Perfil eliminado correctamente.", "success");
          }
        });
      } else {
        this.formPlanType.profiles = this.formPlanType.profiles.filter(
          (profile) => profile.id !== profileId
        );
        showSnackBar("Perfil eliminado correctamente.", "success");
      }
    },

    async addField() {
      const existingField = this.formPlanType.fields.find(
        (field) => field.field_name.trim() === this.field.field_name.trim()
      );
    
      if (existingField) {
        showSnackBar("El campo ya está agregado.", "warning");
        return;
      }

      const newField = { ...this.field };
      this.formPlanType.fields.push(newField);

      this.field = { field_name: null, data_type: "TEXTO", is_required: false };
    
      showSnackBar("Cabecera agregada exitosamente.", "success");
    },

    removeField(fieldName) {
      const existingField = this.planType.custom_fields.find(
        (field) => field.field_name === fieldName
      );
    
      if (existingField) {
        this.$swal({
          text: `El campo "${fieldName}" ya está registrado. ¿Estás seguro de querer eliminarlo?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0039a6",
          cancelButtonColor: "grey",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sí, eliminar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.formPlanType.fields = this.formPlanType.fields.filter(
              (field) => field.field_name !== fieldName
            );
            showSnackBar(`El campo "${fieldName}" ha sido eliminado.`, "success");
          }
        });
      } else {
        this.formPlanType.fields = this.formPlanType.fields.filter(
          (field) => field.field_name !== fieldName
        );
        showSnackBar(`El campo "${fieldName}" ha sido eliminado.`, "success");
      }
    },

    addFieldGroup() {
      const missingField = this.formPlanType.fields.find(
        (field) => field.is_required && !this.fieldValues[field.field_name]?.trim()
      );

      if (missingField) {
        showSnackBar(`El campo "${missingField.field_name}" es requerido.`, "warning");
        return;
      }

      const groupedFields = this.formPlanType.fields.map((field) => ({
        field_name: field.field_name,
        value: this.fieldValues[field.field_name] || null,
      }));

      this.formPlanType.field_groups.push({
        service: [...this.selectedServices],
        grouped_fields: groupedFields,
      });

      this.fieldValues = {};
      this.selectedServices = [];

      this.$nextTick(() => {
        this.$refs.fields.forEach((field) => field.resetValidation());
      });

      showSnackBar("Grupo agregado exitosamente.", "success");
    },

    removeFieldGroup(group) {
      const existingGroup = this.planType.groupings.find(
        (originalGroup) => originalGroup.id === group.id
      );
    
      if (existingGroup) {
        this.$swal({
          text: `Este grupo ya está registrado. ¿Estás seguro de querer eliminarlo?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0039a6",
          cancelButtonColor: "grey",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sí, eliminar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.formPlanType.field_groups = this.formPlanType.field_groups.filter(
              (g) => g !== group
            );
            showSnackBar("El grupo ha sido eliminado.", "success");
          }
        });
      } else {
        this.formPlanType.field_groups = this.formPlanType.field_groups.filter(
          (g) => g !== group
        );
        showSnackBar("El grupo ha sido eliminado.", "success");
      }
    },

    async savePlanType() {
      if (!this.formPlanType.field_groups.length) {
        showSnackBar(
          "Debe agregar al menos una agrupación para guardar el tipo de plan.",
          "warning"
        );
        return;
      }

      const payload = {
        ...this.formPlanType,
        profiles:  this.formPlanType.profiles.map((profile) => profile.id),
      }

      this.$swal({
        text: "¿Estás seguro de actualizar este tipo de plan?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0039a6",
        cancelButtonColor: "grey",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, actualizar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoadingSavePlanType = true;
          const { planTypeId } = this.$route.params;
          const { ok, error } = await this.editPlanType({id: planTypeId, payload:payload});

          if (ok) {

            showSnackBar("Tipo de plan actualizado correctamente.", "success");

            this.$router.push({ name: "ListPlanType" });
          } else {
            let errorMsm = error?.data?.message;
            errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
            showSnackBar(errorMsm, "error");
          }

          this.isLoadingSavePlanType = false;
        }
      });
    }
  },

  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
};